<template>
    <el-container>
        <el-row style="width: 100%">
            <el-row>
                <el-col :span="24">
                <div class="cardTitle">满意度题库</div>
                <el-button type="primary" size="mini" @click="addClass">新建分类</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="5">
                    <el-card shadow="never" style="height: 100%;width:100%">
                        <el-tree :data="classList" :props="{children: 'children', label: 'label'}" @node-click="nodeClick">
                            <span class="custom-tree-node" slot-scope="{node,data}" style="width: 100%">
                                <span :class="node.label==secondClassName?'itemColor': ''">{{ cutString(node.label) }}</span>
                                <el-dropdown class="moreBtn">
                                    <span class="el-dropdown-link">
                                        <i class="el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <div @click="editName(node,data)">编辑</div>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="node.level == 1">
                                            <div @click="addClass(node,data)">新增</div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div @click="deleteClass(data)">删除</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </span>
                        </el-tree>
                    </el-card>
                </el-col>
                <el-col :span="18" :offset="1">
                    <el-card shadow="never">
                        <el-row style="margin-top: 5px">
                            <el-col :span="24">
                                <div class="cardTitle">{{secondClassName}}</div>
                                <el-button type="success" class="cardTitleBtn" size="small" v-if="secondClassName" @click="addQuestion">新建题目</el-button>
                            </el-col>
                            <el-col :span="24">
                                <el-table :data="questionList.records">
                                    <el-table-column label="题目" prop="title"></el-table-column>
                                    <el-table-column label="题目类型" prop="types"></el-table-column>
                                    <el-table-column label="操作">
                                        <template slot-scope="scope">
                                            <el-button type="primary" size="small" @click="editQuestion(scope.row)">编辑</el-button>
                                            <el-button type="danger" size="small" @click="deleteQuestion(scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24">
                                <div class="block" style="margin-top: 20px" v-if="secondClassName" >  
                                    <el-pagination
                                        :current-page="questionList.nowPageNum"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :page-sizes="[ 20, 40, 80]"
                                        :page-size="pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="questionList.totalNum"
                                    >
                                    </el-pagination>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-row>

        <el-dialog title="类型名称" width="20%" :visible.sync="classVisible">
            <el-form :model="classForm">
                <el-form-item>
                    <el-input v-model="className" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="classVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddClass">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加题目" width="50%" :visible.sync="questionVisible">
            <el-form :model="questionForm" label-width="100px">
                <el-row>
                    <el-form-item label="题目">
                        <el-input v-model="questionForm.title" placeholder="请填写题目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="题目类型">
                        <el-radio-group v-model="questionForm.types">
                            <el-radio label="单选"></el-radio>
                            <el-radio label="多选"></el-radio>
                            <el-radio label="填空"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="questionForm.types!='填空'">
                        <el-row>
                        <el-col :span="8">
                            <el-form-item :label="'选项'+(index+1)" :key="index" v-for="(item,index) in questionForm.optionList">
                                <el-input v-model="item.value"  placeholder="请填写选项(例：A)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item :label="'选项值'+(index+1)" :key="index" v-for="(item,index) in questionForm.optionList">
                                <el-input v-model="item.text" style="width: 84%" placeholder="请填写选项值(例：满意)"></el-input>
                                <i v-if="index!=0" class="el-icon-delete" style="margin-left: 10px;cursor:pointer" @click="delItem(index)"></i>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button @click="addItem">新增选项</el-button>
                        </el-col>
                    </el-row>
                    <el-form-item label="问题触发规则">
                        <el-checkbox-group v-model="questionForm.attachOptionRuleArray">
                            <el-checkbox v-for="r in questionForm.optionList" :key="r.value" :label="r.value"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="附加问题">
                        <el-input v-model="questionForm.attachTitle" placeholder="请填写附加问题(例：不满意原因)"></el-input>
                    </el-form-item>
                    </template>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="questionVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddQuestion">确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            // 分类列表
            classList: [],
            classVisible: false,
            questionVisible: false,
            classForm: {
                guid: '',
                name: '',
                parentGuid: ''
            },
            className: '',
            pageSize: 20,
            secondClassName: '',
            questionList: [],
            classGuid: '',
            questionForm: {
                attachOptionRuleArray: [],
                attachTitle: "",
                categoryGuid: "",
                guid: "",
                optionList: [
                    {
                        text: "",
                        value: ""
                    }
                ],
                title: "",
                types: ""
            },
            attachOptionRule: ''
        }
    },
    mounted() {
      let self = this;
      self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      self.getClassTreeStructure();
    },
    methods: {
        // 获取分类树状结构
        getClassTreeStructure() {
            let self = this;
            self.$axios.get('/survey-category/tree',{
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.classList = res.data.data
            })
            .catch(function(err) {
                self.$message({
                    message: "分类列表获取失败：" + err.response.data.message,
                    type: "warning"
                });
            })
        },
        // 新建分类
        addClass(node,data) {
            let self = this;
            console.log('node=',node);
            if(node.level == 1) {
                self.classForm.parentGuid = data.value
                self.classForm.guid = ''
            }
            self.className = ''
            self.classVisible = true
        },
        // 确认新建分类
        doAddClass() {
            let self = this;
            console.log('self.classForm=',self.classForm);
            self.classForm.name = self.className
            self.$axios.post('/survey-category/saveOrUpdate',self.classForm,{
                headers: { token: self.userInfo.token }
            })
            .then(function() {
                self.$message({
                    message: "添加成功",
                    type: "success",
                });
                self.getClassTreeStructure();
                self.classVisible = false
            })
            .catch(function(error) {
                self.$message({
                    message: "添加失败" + error.response.data.message,
                    type: "warning",
                });
            })
        },
        // 编辑按钮
        editName(node,data) {
            let self = this;
            console.log(node);
            if(node.level == 2) {
                self.classForm.parentGuid = node.parent.data.value
            } else if(node.level == 1) {
                self.classForm.parentGuid = ''
            }
            self.classForm.guid = data.value
            self.className = data.label
            self.classVisible = true
        },
        // 删除按钮
        deleteClass(data) {
            let self = this;
            self.$confirm("确定删除" + data.label + "吗？")
            .then(() => {
                self.$axios
                .delete("/survey-category/delete", {
                    data: { guid: data.value },
                    headers: { token: self.userInfo.token },
                })
                .then(function () {
                    self.$message({
                    message: "删除成功",
                    type: "success",
                    });
                    self.getClassTreeStructure();
                })
                .catch(function (error) {
                    self.$message({
                    message: "删除失败" + error.response.data.message,
                    type: "warning",
                    });
                });
            })
            .catch(() => {});
        },
        // 展示题目
        nodeClick(data,node) {
            let self = this
            console.log('node=',node);
            if(node.level == 2) {
                self.questionList = []
                self.secondClassName = data.label
                self.classGuid = data.value
                self.getQuestion(self.classGuid)
            }
        },
        // 获取题目
        getQuestion(guid,pageNum) {
            let self = this
            self.$axios.get('/survey-question/page?pageNum=' + (pageNum || 1) + '&pageSize='+self.pageSize+'&categoryGuid='+guid,{
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.questionList = res.data.data
                console.log(guid);
                console.log(self.questionList);
            })
            .catch(function (error) {
                self.$message({
                message: "题目列表获取失败：" + error.response.data.message,
                type: "warning",
                });
            });
        },
        // 点击添加题目按钮
        addQuestion() {
            let self = this
            self.questionForm = {
                attachOptionRuleArray: [],
                attachTitle: "",
                categoryGuid: "",
                guid: "",
                optionList: [
                    {
                        text: "",
                        value: ""
                    }
                ],
                title: "",
                types: ""
            }
            // self.attachOptionRule = ''
            self.questionVisible = true
        },
        // 新增选项
        addItem() {
            let self = this
            self.questionForm.optionList.push({
                text: '',
                value: ''
            });
        },
        // 删除新增选项
        delItem(index) {
            let self = this
            self.questionForm.optionList.splice(index,1)
            console.log(index);
        },
        // 确认添加题目
        doAddQuestion() {
            let self = this
            self.questionForm.categoryGuid = self.classGuid
            // if(self.attachOptionRule != '') {
            //     self.questionForm.attachOptionRuleArray.push(self.attachOptionRule)
            // }
            if(self.questionForm.types == '填空') {
                self.questionForm.optionList = []
                self.questionForm.attachOptionRuleArray = [],
                self.questionForm.attachTitle = ""
            }
            console.log(self.questionForm);
            self.$axios.post('/survey-question/saveOrUpdate',self.questionForm,{
                headers: { token: self.userInfo.token },
            })
            .then(function() {
                self.$message({
                    message: "添加成功",
                    type: "success",
                });
                self.getQuestion(self.classGuid);
                self.questionVisible = false
            })
            .catch(function(error) {
                self.$message({
                    message: "添加失败" + error.response.data.message,
                    type: "warning",
                });
            })
        },
        // 编辑题目
        editQuestion(row) {
            console.log(row);
            let self = this
            self.questionForm = {
                attachOptionRuleArray: row.attachOptionRuleArray,
                attachTitle: row.attachTitle,
                categoryGuid: "",
                guid: row.guid,
                optionList: row.optionList,
                title: row.title,
                types: row.types
            }
            self.questionVisible = true
        },
        // 删除题目
        deleteQuestion(row) {
            let self = this;
            self.$confirm("确定删除" + row.title + "吗？")
            .then(() => {
                self.$axios
                .delete("/survey-question/delete", {
                    data: { guid: row.guid },
                    headers: { token: self.userInfo.token },
                })
                .then(function () {
                    self.$message({
                    message: "删除成功",
                    type: "success",
                    });
                    self.getQuestion(self.classGuid);
                })
                .catch(function (error) {
                    self.$message({
                    message: "删除失败" + error.response.data.message,
                    type: "warning",
                    });
                });
            })
            .catch(() => {});
        },
        // 改变显示个数
        handleSizeChange(val) {
            this.pageSize = val * 1;
            this.getQuestion(this.classGuid)
        },
        // 改变页码
        handleCurrentChange(val) {
            this.getQuestion(this.classGuid,val)
        },
        cutString(str){
           if(str.length>8){
               return str.slice(0,8)+"..."
           }else{
               return str
           }
        }
    }
}
</script>

<style>
    .moreBtn{
        float: right;
        color: #409EFF;
        cursor: pointer;
    }
    .itemColor {
        color: #409EFF;
    }
    .ql-editor{
        height:150px;
    }
    .el-tree-node__content{
        height: 40px;
    }
</style>